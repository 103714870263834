<template>
  <div class="sub-account">
    <query-header :handle-click-add-btn="handleClickAddBtn" :handle-query="handleQuery"
                  :handle-refresh="getSubaccountList"
                  :query="queryForm"
                  :select-options="roleList"
                  :show-add-btn="userInfo.authArr.includes('SYSTEM_USER_ADD_SUB_ACCOUNT') && (userInfo.global && !userInfo.global.ldapEnable)" add-btn-text="创建子账号"
                  placeholder="请输入姓名/子账号查询" title="角色"
    >
    </query-header>
    <a-table :columns="subaccountColumns" :data-source="subaccountList" :locale="{emptyText: '暂无数据'}"
             :rowKey="record=>record.uid"
             size="small">
      <template slot="subAccount" slot-scope="record">
        <div class="copy-account">
          <div class="text">{{ record.subAccount }}</div>
          <div class="square"/>
          <cc-iconfont :size="12" name="copy" @click.native="copyText(`${record.subAccount}`, '复制账号成功')" style="margin-left: 3px;"/>
        </div>
      </template>
      <template slot="status" slot-scope="record">
        <cc-status :content="record.disable ? '停用' : '启用'"
                   :type="record.disable ? 'error':'success'"/>
      </template>
      <template slot="createTime" slot-scope="record">
        <div>{{ record.gmtCreate | formatTime('YYYY/MM/DD') }}</div>
      </template>
      <template slot="action" slot-scope="record">
        <div class="action">
          <a-button v-if="userInfo.authArr.includes('SYSTEM_DS_AUTH_MODIFY_USER_DS_AUTH')" :disabled="record.disable"
                    size="small"
                    type="link"
                    @click="goAuthPage(record)">
            数据库授权
          </a-button>
          <a-button type="link" @click="handleClickAllAuth(record)">
            已授权限
          </a-button>
          <a-button v-if="userInfo.authArr.includes('SYSTEM_UPDATE_USER_ROLE')" :disabled="record.disable" size="small"
                    type="link"
                    @click="handleClickChangeRoleModal(record)">修改角色
          </a-button>
          <a-button v-if="userInfo.authArr.includes('SYSTEM_ACCOUNT_ENABLE_MANAGE')" size="small" type="link"
                    @click="handleTriggerStopAccount(record)">
            {{ record.disable ? '启用' : '停用' }}
          </a-button>
          <a-popconfirm v-if="userInfo.authArr.includes('SYSTEM_USER_DELETE_SUB_ACCOUNT')" cancel-text="取消" ok-text="确认"
                        title="确定删除该子账号吗？"
                        @confirm="handleDeleteSubaccount(record)">
            <a-button size="small" type="link">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <add-subaccount-modal :visible="showAddNewSubccountModal" :handle-close="handleCloseAddSubaccountModal"
                          :role-list="roleList"
                          :get-subaccount-list="getSubaccountList" v-if="showAddNewSubccountModal"/>
    <change-role-modal :visible="showChangeRoleModal" :role-list="roleList" v-if="showChangeRoleModal"
                       :selected-subaccount="selectedSubaccount" :handle-close="handleCloseChangeRoleModal"
                       :get-subaccount-list="getSubaccountList"/>
    <all-auth-modal :visible="showAllAuthModal" :handle-close-modal="hideAllAuthModal" :sub-account="selectedSubaccount" v-if="showAllAuthModal"/>
  </div>
</template>

<script>
import Vue from 'vue';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { generateData } from '@/utils';
import { mapState } from 'vuex';
import copyMixin from '@/mixins/copyMixin';
import enterOpPwdMixin from '@/mixins/modal/enterOpPwdMixin';
import AddSubaccountModal from '@views/system/subaccount/components/AddSubaccountModal.vue';
import ChangeRoleModal from '@views/system/subaccount/components/ChangeRoleModal.vue';
import QueryHeader from '../components/QueryHeader.vue';
import AllAuthModal from './components/AllAuthModal.vue';

const EMPTY_ROLE = {
  roleName: '',
  index: -1,
  isNew: true
};

// 数据和方法带2的都是数据库管理权限相关
export default {
  name: 'SubAccount',
  mixins: [copyMixin, enterOpPwdMixin],
  components: {
    AllAuthModal,
    ChangeRoleModal,
    AddSubaccountModal,
    QueryHeader
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    }),
    subaccountColumns() {
      const {
        role,
        query
      } = this.subaccountFilterInfo;

      return [
        {
          title: '姓名',
          dataIndex: 'username'
        },
        {
          title: '账号',
          key: 'subAccount',
          with: 400,
          filteredValue: [query] || null,
          onFilter: (value, record) => record.subAccount.includes(value) || record.username.includes(value),
          scopedSlots: { customRender: 'subAccount' }
        },
        {
          title: '绑定的账号',
          dataIndex: 'bindAccount'
        },
        {
          title: '账号类型',
          dataIndex: 'bindType'
        },
        {
          title: '角色',
          key: 'roleName',
          filteredValue: [role] || null,
          onFilter: (value, record) => value === 'all' || record.roleId === value || value === '',
          dataIndex: 'roleName'
        },
        {
          title: '状态',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 320,
          scopedSlots: { customRender: 'action' }
        }
      ];
    },
    selectedDatasourceColumns() {
      const {
        env,
        text
      } = this.selectedDatasourceFilterInfo;
      const envName = this.envListMap[env] ? this.envListMap[env].envName : '';
      const dsColumns = [
        {
          title: '环境',
          key: 'env',
          scopedSlots: { customRender: 'env' },
          filteredValue: [envName] || null,
          onFilter: (value, record) => (record.dsEnvName && record.dsEnvName === value) || (record.dsEnv && record.dsEnv === value) || value === ''
        },
        {
          title: '数据库',
          key: 'name',
          filteredValue: [text] || null,
          onFilter: (value, record) => record.key.includes(value),
          scopedSlots: { customRender: 'name' }
        }
      ];
      this.dsAuthKind.forEach((auth) => {
        dsColumns.push({
          slots: { title: `${auth.kindValue}-title` },
          scopedSlots: { customRender: auth.kindValue },
          align: 'center'
        });
      });

      dsColumns.push({
        title: '操作',
        scopedSlots: { customRender: 'action' }
      });

      return dsColumns;
    }
  },
  data() {
    return {
      selectedUid: '',
      showAllAuthModal: false,
      preRequestData: {},
      selectedDatasourceFilterInfo: {
        env: '',
        text: ''
      },
      defaultExpandedTreeKeys2: [],
      DS_AUTH_KIND_TYPE: {
        OPERATION: 'OPERATION',
        MANAGE: 'MANAGE'
      },
      checkedDsAuthKindType: 'OPERATION',
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      envListMap: {},
      queryForm: {
        type: 'all',
        text: ''
      },
      opQueryEnv: {
        env: undefined,
        text: ''
      },
      opQueryEnv2: {
        env: undefined,
        text: ''
      },
      manageQueryEnv: {
        env: undefined,
        text: ''
      },
      manageQueryEnv2: {
        env: undefined,
        text: ''
      },
      showChangeAuthorityModal: false,
      showChangeRoleModal: false,
      showAddNewSubccountModal: false,
      showManageRoleModal: false,
      selectedSubaccount: {
        name: '',
        account: '',
        role: '',
        status: '',
        createTime: ''
      },
      selectOptions: [],
      subaccountList: [],
      subaccountFilterInfo: {
        role: '',
        query: ''
      },
      roleColumns: [
        {
          title: '角色名称',
          key: 'roleName'
        }, {
          title: '操作',
          key: 'action',
          slot: 'action'
        }
      ],
      roleList: [],
      allAuthLabel: [],
      selectedRole: EMPTY_ROLE,
      selectedRoleRules: {
        roleName: [{
          required: true,
          message: '角色名称不能为空',
          trigger: 'blur'
        }]
      },
      datasourceTree: {},
      datasourceTree2: {},
      datasourceTreeMap: {},
      datasourceTreeMap2: {},
      selectedDatasource: [],
      selectedDatasource2: [],
      selectedDatasourceMap: {},
      selectedDatasourceMap2: {},
      checkedTreeKeys: [],
      checkedTreeKeys2: [],
      expandTreeKeys: [],
      selectedDatasourceListNum: 0,
      selectedDatasourceListNum2: 0,
      selectedDatasourceNum: {},
      selectedDatasourceNum2: {},
      loadedKeys: [],
      dsAuthKind: [],
      userDsAuthList: []
    };
  },
  watch: {
    selectedDatasourceMap: {
      handler() {
        const selectedDatasource = Object.values(this.selectedDatasourceMap);
        this.selectedDatasourceListNum = selectedDatasource.length;
        const num = {};
        this.dsAuthKind.forEach((auth) => {
          selectedDatasource.forEach((ds) => {
            const { kindValue } = auth;
            const has = ds[auth.kindValue];
            if (has) {
              if (num[kindValue]) {
                num[kindValue]++;
              } else {
                num[kindValue] = 1;
              }
            }
          });
        });
        this.checkedTreeKeys = Object.keys(this.selectedDatasourceMap);
        this.selectedDatasourceNum = num;
      },
      deep: true
    },
    selectedDatasourceMap2: {
      handler() {
        this.selectedDatasourceListNum2 = Object.values(this.selectedDatasourceMap2).length;
        this.checkedTreeKeys2 = Object.keys(this.selectedDatasourceMap2);
      },
      deep: true
    }
  },
  methods: {
    handleClickAllAuth(record) {
      this.showAllAuthModal = true;
      this.selectedSubaccount = cloneDeep(record);
    },
    hideAllAuthModal() {
      this.showAllAuthModal = false;
    },
    goAuthPage(record) {
      this.$router.push({ path: `sub_account/auth/${record.uid}?name=${record.subAccount}` });
    },
    handleSelectedDsOpQuery() {
      this.selectedDatasourceFilterInfo = { ...this.opQueryEnv2 };
    },
    async handleOpDsListQuery() {
      const {
        env = '',
        text
      } = this.opQueryEnv;
      const leafStyle = {
        show: 'display: block',
        hide: 'display: none'
      };
      const envName = env ? this.envListMap[env].envName : '';
      for (const key in this.datasourceTree) {
        let leafShow = leafStyle.hide;
        if (this.datasourceTree[key].title.includes(envName)) {
          this.datasourceTree[key].children.forEach((leaf2) => {
            let leaf2Show = leafStyle.hide;
            if (leaf2.instanceDesc.includes(text)) {
              leaf2Show = leafStyle.show;
              leafShow = leafStyle.show;
            } else {
              leaf2Show = leafStyle.hide;
            }
            if (leaf2.children) {
              leaf2.children.forEach((leaf3) => {
                let leaf3Show = leafStyle.hide;
                if (leaf3.title.includes(text) || leaf2.instanceDesc.includes(text)) {
                  leaf3Show = leafStyle.show;
                  leaf2Show = leafStyle.show;
                }
                leaf3.style = leaf3Show;
              });
            }
            leaf2.style = leaf2Show;
            if (leaf2Show === leafStyle.show) {
              leafShow = leafStyle.show;
            }
          });
        }
        this.datasourceTree[key].style = leafShow;
      }

      this.datasourceTree = cloneDeep(this.datasourceTree);
    },
    async handleDeleteSubaccount(subaccount) {
      const res = await this.$services.deleteSubAccount({
        data: { subAccount: subaccount.subAccount },
        msg: '删除子账号成功'
      });

      if (res.success) {
        this.getSubaccountList();
      }
    },
    handleExpand(expandedKeys) {
      this.expandTreeKeys = expandedKeys;
    },
    hideChangeAuthorityModal() {
      this.showChangeAuthorityModal = false;
      this.clearTree();
    },
    async handleChangeAuthority() {
      const dsManagerAuthList = [];
      const dsOpsAuthList = [];
      const deletes = [];
      const updates = [];
      const { userDsAuthListMap } = this;
      for (const [key, ds] of Object.entries(this.selectedDatasourceMap)) {
        if (!userDsAuthListMap[key]) {
          const addDs = {
            dataSourceId: ds.dataSourceId,
            levelElements: [ds.title],
            dsAuthKinds: []
          };

          const addOperateDs = cloneDeep(addDs);
          this.dsAuthKind.forEach((auth) => {
            if (ds[auth.kindValue]) {
              if (auth.dsAuthKindType === this.DS_AUTH_KIND_TYPE.OPERATION) {
                addOperateDs.dsAuthKinds.push(auth.kindValue);
              }
            }
          });

          if (addOperateDs.dsAuthKinds.length) {
            dsOpsAuthList.push(addOperateDs);
          }
        } else {
          const newAuth = [];
          this.dsAuthKind.forEach((auth) => {
            if (ds[auth.kindValue]) {
              newAuth.push(auth.kindValue);
            }
          });
          const oldSchema = userDsAuthListMap[key];
          const oldAuth = oldSchema.dsAuthKinds;
          const updateDs = {
            dataSourceId: oldSchema.dataSourceId,
            dsAuthId: oldSchema.id
          };
          if (!isEqual(newAuth, oldAuth)) {
            updateDs.dsAuthKinds = newAuth;
            updates.push(updateDs);
          }
          delete userDsAuthListMap[key];
        }
      }
      for (const [key, ds] of Object.entries(this.selectedDatasourceMap2)) {
        if (!userDsAuthListMap[key]) {
          const addDs = {
            dataSourceId: ds.id,
            dsAuthKinds: []
          };

          const addManagerDs = cloneDeep(addDs);
          this.dsAuthKind.forEach((auth) => {
            if (ds[auth.kindValue]) {
              if (auth.dsAuthKindType === this.DS_AUTH_KIND_TYPE.MANAGE) {
                addManagerDs.dsAuthKinds.push(auth.kindValue);
              }
            }
          });

          if (addManagerDs.dsAuthKinds.length) {
            dsManagerAuthList.push(addManagerDs);
          }
        } else {
          const newAuth = [];
          this.dsAuthKind.forEach((auth) => {
            if (ds[auth.kindValue]) {
              newAuth.push(auth.kindValue);
            }
          });
          const oldSchema = userDsAuthListMap[key];
          const oldAuth = oldSchema.dsAuthKinds;
          const updateDs = {
            dataSourceId: oldSchema.dataSourceId,
            dsAuthId: oldSchema.id
          };
          if (!isEqual(newAuth, oldAuth)) {
            updateDs.dsAuthKinds = newAuth;
            updates.push(updateDs);
          }
          delete userDsAuthListMap[key];
        }
      }

      Object.values(userDsAuthListMap)
        .forEach((schema) => {
          deletes.push({
            dataSourceId: schema.dataSourceId,
            dsAuthId: schema.id
          });
        });
      const data = {
        dsManagerAuthList,
        dsOpsAuthList,
        updates,
        deletes,
        authedUid: this.selectedSubaccount.uid
      };

      const res = await this.$services.modifyUserDsAuth({
        data,
        msg: '修改权限成功'
      });
      if (res.success) {
        this.hideChangeAuthorityModal();
        this.clearTree();
      }
    },
    clearTree() {
      this.datasourceTree = {};
      this.datasourceTreeMap = {};
      this.checkedTreeKeys = [];
      this.expandTreeKeys = [];
      this.selectedDatasourceListNum = 0;
      this.selectedDatasourceNum = {};
      this.selectedDatasourceMap = {};
      this.selectedDatasourceColumns = [];
      this.checkedDsAuthKindType = this.DS_AUTH_KIND_TYPE.OPERATION;
    },
    async getSchema(dataSourceId, treeNode, expand) {
      const res = await this.$services.listSchemaFirstLevel({
        data: { dataSourceId, useVisibility: true },
        page: 'subaccount'
      });
      if (res.success) {
        const treeMap = this.datasourceTreeMap;
        if (res.data.nameList.length) {
          treeNode.dataRef.children = [];
          const keys = [];
          res.data.nameList.forEach((name) => {
            const {
              dsEnvName,
              instanceDesc
            } = treeNode.dataRef;
            const key = `${treeNode.dataRef.key}/${name}`;
            keys.push(key);
            const node = {
              dsEnvName,
              instanceDesc,
              deep: 3,
              title: name,
              dataSourceId,
              key,
              isLeaf: true
            };
            treeNode.dataRef.children.push(node);
            treeMap[key] = node;

            if (this.preRequestData.isPre) {
              this.expandTreeKeys = [...this.expandTreeKeys, treeNode.dataRef.key];
            }

            if (expand) {
              const { selectedDatasourceMap } = this;
              keys.forEach((k) => {
                const ds = this.datasourceTreeMap[k];
                if (ds) {
                  selectedDatasourceMap[k] = this.userDsAuthListMap[k] || ds;
                }
              });
              this.checkedTreeKeys = Array.from(new Set([...this.checkedTreeKeys, ...keys]));
              this.selectedDatasourceMap = selectedDatasourceMap;
              this.selectedDatasourceListNum = Object.values(selectedDatasourceMap).length;
              this.preRequestData = {};
              this.$forceUpdate();
            }
          });
          this.datasourceTree = { ...this.datasourceTree };
          this.datasourceTreeMap = { ...treeMap };
        } else {
          const node = {
            title: '--- 您当前实例下无权限，请联系主账号分配 ---',
            key: '无权限',
            isLeaf: true,
            checkable: false,
            disabled: true,
            selectable: false
          };
          treeNode.dataRef.children = [node];
          treeMap[node.key] = node;
          this.datasourceTree = { ...this.datasourceTree };
          this.datasourceTreeMap = { ...treeMap };
          // throw new Error('get schema error');
        }
      } else {
        this.preRequestData = {
          dataSourceId,
          treeNode,
          expand,
          isPre: true
        };
        throw new Error('get schema error');
      }
    },
    onLoadTreeData(treeNode) {
      return new Promise(async (resolve) => {
        if (treeNode.dataRef.deep === 3 || treeNode.dataRef.children) {
          resolve();
          return;
        }
        try {
          await this.getSchema(treeNode.dataRef.id, treeNode);
          resolve();
        } catch (e) {
          treeNode.dataRef.isLeaf = false;
          this.datasourceTree = { ...this.datasourceTree };
          console.log(e);
          this.expandTreeKeys = this.expandTreeKeys.filter((key) => key !== treeNode.dataRef.key);
          resolve();
        }
      });
    },
    handleDeleteDs(ds) {
      Vue.delete(this.selectedDatasourceMap, ds.key);
      this.selectedDatasourceListNum -= 1;
      this.dsAuthKind.forEach((auth) => {
        if (ds[auth.kindValue]) {
          Vue.set(this.selectedDatasourceNum, auth.kindValue, this.selectedDatasourceNum[auth.kindValue] - 1);
        }
      });
      this.checkedTreeKeys = this.checkedTreeKeys.filter((key) => key !== ds.key);
      this.$forceUpdate();
    },
    handleDeleteDs2(ds) {
      Vue.delete(this.selectedDatasourceMap2, ds.key);
      this.selectedDatasourceListNum2 -= 1;
      this.dsAuthKind.forEach((auth) => {
        if (ds[auth.kindValue]) {
          Vue.set(this.selectedDatasourceNum2, auth.kindValue, this.selectedDatasourceNum2[auth.kindValue] - 1);
        }
      });
      this.checkedTreeKeys2 = this.checkedTreeKeys2.filter((key) => key !== ds.key);
      this.$forceUpdate();
    },
    handleChangeCheckbox(ds, type, e) {
      Vue.set(this.selectedDatasourceMap[ds.key], type, e.target.checked);
      const startNum = this.selectedDatasourceNum[type] || 0;
      Vue.set(this.selectedDatasourceNum, type, (startNum + (e.target.checked ? 1 : -1)));
      this.$forceUpdate();
    },
    handleChangeCheckbox2(ds, type, e) {
      Vue.set(this.selectedDatasourceMap2[ds.key], type, e.target.checked);
      const startNum = this.selectedDatasourceNum2[type] || 0;
      Vue.set(this.selectedDatasourceNum2, type, (startNum + (e.target.checked ? 1 : -1)));
      this.$forceUpdate();
    },
    async handleTreeChange(checkedKeys, data) {
      const {
        deep,
        id,
        key
      } = data.node.dataRef;
      const selectedDatasourceMap = this.selectedDatasourceMap;
      if (deep === 3) {
        if (data.node.checked) {
          delete selectedDatasourceMap[key];
        } else {
          const ds = this.datasourceTreeMap[key];
          if (ds) {
            selectedDatasourceMap[key] = this.userDsAuthListMap[key] || ds;
          }
        }

        this.selectedDatasourceMap = { ...selectedDatasourceMap };
      } else if (deep === 2) {
        if (!data.node.checked) {
          await this.getSchema(id, data.node, true);
        } else {
          const selectedDataSourceMap = this.selectedDatasourceMap;
          const deleteKeys = [];
          if (data.node.dataRef.children) {
            data.node.dataRef.children.forEach((child) => {
              delete selectedDataSourceMap[child.key];
              deleteKeys.push(child.key);
            });
          }

          this.selectedDataSourceMap = selectedDataSourceMap;
          this.selectedDatasourceListNum = Object.values(selectedDataSourceMap).length;
          this.checkedTreeKeys = this.checkedTreeKeys.filter((k) => !deleteKeys.includes(k));
        }
      }
    },
    async handleTreeChange2(checkedKeys, data) {
      const {
        deep
      } = data.node.dataRef;
      const selectedDatasourceMap2 = {};
      if (deep === 2) {
        checkedKeys.forEach((key) => {
          const ds = this.datasourceTreeMap2[key];
          if (ds) {
            selectedDatasourceMap2[key] = this.userDsAuthListMap[key] ? this.selectedDatasourceMap[key] : ds;
          }
        });
        this.selectedDatasourceMap2 = selectedDatasourceMap2;
        this.checkedTreeKeys2 = checkedKeys;
      }
    },
    async getEnvList(data) {
      const res = await this.$services.listDsEnv({ data });
      if (res.success) {
        return res.data;
      }
    },
    async getUserDsAuth(ownerUid) {
      const res = await this.$services.listUserDsAuth({ data: { ownerUid } });
      if (res.success) {
        this.userDsAuthList = res.data;
        const selectedDatasourceMap = {};
        const selectedDatasourceMap2 = {};
        const nums = {};
        const nums2 = {};
        const userDsAuthListMap = {};
        res.data.forEach((schema) => {
          const {
            dsAuthKinds,
            path,
            dsEnv,
            dsDesc
          } = schema;
          const key = path === '/' ? `${dsEnv}/${dsDesc}` : `${dsEnv}/${dsDesc}${path}`;
          userDsAuthListMap[key] = schema;
          this.checkedTreeKeys.push(key);
          dsAuthKinds.forEach((auth) => {
            schema[auth] = true;
            if (path === '/') {
              if (nums2[auth]) {
                nums2[auth]++;
              } else {
                nums2[auth] = 1;
              }
            } else {
              if (nums[auth]) {
                nums[auth]++;
              } else {
                nums[auth] = 1;
              }
            }
          });
          schema.title = path === '/' ? dsDesc : path.split('/')[1];
          schema.key = key;
          if (path === '/') {
            selectedDatasourceMap2[key] = schema;
          } else {
            selectedDatasourceMap[key] = schema;
          }
        });
        this.selectedDatasourceMap = selectedDatasourceMap;
        this.selectedDatasourceMap2 = selectedDatasourceMap2;
        this.selectedDatasourceListNum = Object.values(selectedDatasourceMap).length;
        this.selectedDatasourceListNum2 = Object.values(selectedDatasourceMap2).length;
        this.userDsAuthListMap = userDsAuthListMap;
        this.selectedDatasourceNum = { ...nums };
        this.selectedDatasourceNum2 = { ...nums2 };
      }
    },
    async getDsAuthKind() {
      const res = await this.$services.listAllDsAuthKind();
      if (res.success) {
        this.dsAuthKind = res.data;
        res.data.forEach((auth) => {
          this.selectedDatasourceNum[auth.kindValue] = 0;
        });

        this.selectedDatasourceNum = { ...this.selectedDatasourceNum };
      }
    },
    async handleClickChangeRoleModal(row) {
      await this.getRoleList();
      this.selectedSubaccount = cloneDeep(row);
      this.showChangeRoleModal = true;
    },
    async getDsListForManage(data = {}) {
      const res = await this.$services.getDsList({ data: { ...data, useVisibility: true } });
      const tree = {};
      const datasourceTreeMap = {};
      const expandKeys = [];
      if (res.success) {
        res.data.forEach((ds) => {
          const {
            dsEnvName,
            dsEnvId,
            instanceDesc
          } = ds;
          datasourceTreeMap[`${dsEnvName}/${instanceDesc}`] = {
            ...ds,
            title: instanceDesc,
            deep: 2,
            key: `${dsEnvName}/${instanceDesc}`
          };
          if (tree[dsEnvId]) {
            tree[dsEnvId].children.push({
              ...ds,
              title: instanceDesc,
              deep: 2,
              key: `${dsEnvName}/${instanceDesc}`
            });
          } else {
            expandKeys.push(dsEnvName);
            tree[dsEnvId] = {
              title: dsEnvName,
              deep: 1,
              key: dsEnvName,
              checkable: false,
              children: [{
                ...ds,
                title: instanceDesc,
                deep: 2,
                key: `${dsEnvName}/${instanceDesc}`
              }]
            };
          }
        });
      }
      this.datasourceTree = cloneDeep(tree);
      this.datasourceTree2 = cloneDeep(tree);
      this.datasourceTreeMap = cloneDeep(datasourceTreeMap);
      this.datasourceTreeMap2 = cloneDeep(datasourceTreeMap);
      this.defaultExpandedTreeKeys2 = expandKeys;
    },
    async handleClickChangeAuthorityModal(row) {
      this.selectedSubaccount = cloneDeep(row);
      await this.getDsAuthKind();
      await this.getUserDsAuth(row.uid);
      const data = await this.getEnvList({});
      const envListMap = {};
      data.forEach((env) => {
        envListMap[env.id] = env;
      });

      await this.getDsListForManage();

      this.showChangeAuthorityModal = true;
      this.envListMap = envListMap;
    },
    handleCloseAddSubaccountModal() {
      this.showAddNewSubccountModal = false;
    },
    handleCloseChangeRoleModal() {
      this.showChangeRoleModal = false;
    },
    async getSubaccountList(data = {}) {
      const res = await this.$services.getSubAccountList({ data });
      if (res.success) {
        this.subaccountList = generateData(res.data);
      }
    },
    async getRoleList() {
      const roleListRes = await this.$services.listRole();
      if (roleListRes.success) {
        roleListRes.data.forEach((role) => {
          role.name = role.roleName;
          role.value = role.id;
        });
        this.roleList = roleListRes.data;
      }
    },
    async getAllAuthLabel() {
      const subAccountRes = await this.$services.listAllAuthLabel();
      const temp = {};
      if (subAccountRes.success) {
        const { data } = subAccountRes;
        data.forEach((label) => {
          const {
            labelKindI18n,
            labelNameI18n,
            labelValue
          } = label;
          if (temp[labelKindI18n]) {
            temp[labelKindI18n].children.push({
              labelNameI18n,
              labelValue,
              label: labelNameI18n,
              value: labelValue
            });
          } else {
            temp[labelKindI18n] = {
              name: labelKindI18n,
              children: [{
                labelNameI18n,
                labelValue,
                label: labelNameI18n,
                value: labelValue
              }]
            };
          }
        });
        this.allAuthLabel = temp;
      }
    },
    handleQuery() {
      const {
        type,
        text
      } = this.queryForm;
      this.subaccountFilterInfo = {
        role: type,
        query: text
      };
    },
    async handleClickAddBtn() {
      await this.getRoleList();
      this.showAddNewSubccountModal = true;
    },
    async handleTriggerStopAccount(row) {
      const {
        disable,
        uid
      } = row;
      const data = {
        disable: !disable,
        uid
      };
      const res = await this.$services.stopSubAccount({
        data,
        msg: disable ? '启用成功' : '停用成功'
      });
      if (res.success) {
        await this.getSubaccountList();
        // Vue.set(this.accountList[index], 'disable', !disable);
      }
    },
    handleCheckboxChange(type, e) {
      const temp = this.selectedDatasourceMap;
      for (const ds in temp) {
        temp[ds][type] = e.target.checked;
      }
      this.selectedDatasourceMap = temp;
      Vue.set(this.selectedDatasourceNum, type, e.target.checked ? this.selectedDatasourceListNum : 0);
      this.$forceUpdate();
    },
    handleCheckboxChange2(type, e) {
      console.log(type, e.target.checked, this.selectedDatasourceListNum2);
      const temp = this.selectedDatasourceMap2;
      for (const ds in temp) {
        temp[ds][type] = e.target.checked;
      }
      this.selectedDatasourceMap2 = temp;
      Vue.set(this.selectedDatasourceNum2, type, 0);
      this.$forceUpdate();
    },
    handlePreRequest() {
      const {
        dataSourceId,
        treeNode,
        expand
      } = this.preRequestData;
      this.getSchema(dataSourceId, treeNode, expand);
    }
  },
  created() {
    this.getSubaccountList();
    this.getRoleList();
    this.getAllAuthLabel();
    window.$bus.on('subaccount_listfirstlevel_callback', this.handlePreRequest);
  },
  destroyed() {
    window.$bus.off('subaccount_listfirstlevel_callback');
  }
};
</script>

<style lang="less">

.sub-account {
  .copy-account {
    display: flex;
    align-items: center;
    cursor: pointer;

    .square {
      width: 15px;
      height: 12px;
    }

    i {
      display: none;
    }

    &:hover {
      i {
        display: block;
      }

      .square {
        display: none;
      }
    }
  }

  .actions {
    font-size: 12px;
  }
}

.manage-role-modal {
  display: flex;

  .left {
    .title {
      margin-bottom: 10px;

      span {
        color: #888;

        &:first-child {
          color: #333;
          font-weight: bold;
          margin-right: 10px;
        }
      }
    }

    .role-table {
      display: flex;
      flex-direction: column;
      height: 400px;
      border: 1px solid rgba(234, 234, 234, 1);
    }
  }

  .new-role {
    flex: 1;
    padding: 20px;
  }
}

.new-subaccount-modal {
  .ivu-input-wrapper {
    width: 420px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 18px;
  }
}

.change-authority-modal {
  .modal-content {
    display: flex;

    & > div {
      display: flex;
      flex-direction: column;

      .title {
        color: #333;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .content {
        border: 1px solid #EAEAEA;

        .query {
          display: flex;
          height: 53px;
          align-items: center;
          justify-content: space-around;
          border-bottom: 1px solid rgba(234, 234, 234, 1);
        }

        .tree {
          height: 457px;
          overflow: scroll;
        }
      }
    }

    .left {
      width: 456px;
      margin-right: 10px;
    }

    .right {
      flex: 1;

      .query {
        justify-content: flex-start !important;

        & > * {
          margin-left: 10px;
        }
      }
    }
  }

  .ant-table-column-title {
    text-align: center;
  }
}
</style>
